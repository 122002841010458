<template lang="">
  <header class="flex justify-center w-full border-b">
    <div class="p-3 flex justify-between max-w-7xl w-full">
        <div class="flex items-center cursor-pointer" @click="$router.push({name: 'dashboard'})">
          <img src="@/assets/logo.png" class="h-6 w-6" alt="">
          <span  class="ml-1 font-bold text-gray-800 text-2xl">
          Ledgerflow
          </span>
        </div>
        <div class="flex items-center">
          <!--
          <div class="relative inline-block text-left">
            <div v-if="org">
              <button @click="showOrgDropdown = !showOrgDropdown;isOpen=false;" type="button" class="inline-flex w-full justify-center gap-x-1.5 rounded-2xl bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                {{ org.org.name }}
                <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
            -->

            <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <!--
            <div v-if="showOrgDropdown && orgs" class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-gray-400 ring-opacity-4 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div class="py-1" role="none">
              -->
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <!--
                <button type="button" @click="orgChange(org)" :key="index" v-for="(org, index) in orgs" class="text-gray-700 block px-4 py-2 text-sm w-full text-start hover:bg-gray-100" role="menuitem" tabindex="-1" id="menu-item-0">{{org.org.name}}</button>
              </div>
              
            </div>
          </div>
          -->
          <fragment v-if="plan">
            <button 
              v-if="plan.type == 'PREPAID'" 
              type="button" 
              @click="$router.push({ name: 'plans' })" 
              class="hidden sm:flex items-center upgrade-button"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18" />
              </svg>
              <span>{{ plan && plan.type == 'PREPAID' ? 'Renew' : 'Upgrade' }}</span>
            </button>
          </fragment>
          <fragment v-else>
            <button 
              type="button" 
              @click="$router.push({ name: 'plans' })" 
              class="hidden sm:flex items-center upgrade-button"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18" />
              </svg>
              <span>Upgrade</span>
            </button>
          </fragment>

          <div class="relative text-left ml-2">
            <button @click="isOpen = !isOpen;showOrgDropdown=false" type="button" class="focus:text-white items-center align-middle">
              <svg class="w-6 h-6 fill-current text-gray-800 cursor-pointer" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title/><g id="about"><path d="M16,16A7,7,0,1,0,9,9,7,7,0,0,0,16,16ZM16,4a5,5,0,1,1-5,5A5,5,0,0,1,16,4Z"/><path d="M17,18H15A11,11,0,0,0,4,29a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1A11,11,0,0,0,17,18ZM6.06,28A9,9,0,0,1,15,20h2a9,9,0,0,1,8.94,8Z"/></g></svg>
            </button>
            <div v-if="isOpen" class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1  ring-gray-400 ring-opacity-4 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <a @click="$router.push({ name: 'billing' })" class="block text-gray-500  sm:text-gray-800 px-2 py-1 sm:mt-0 sm:hover:bg-gray-200 cursor-pointer">Billing</a>
                <a @click="$router.push({ name: 'profile' })" class="block text-gray-500  sm:text-gray-800 px-2 py-1 sm:mt-0 sm:hover:bg-gray-200 cursor-pointer">Profile</a>
                <a @click="logout" class="block text-gray-500  sm:text-gray-800 px-2 py-1 sm:mt-0 sm:hover:bg-gray-200 cursor-pointer">Logout</a>
              </div>
            </div>
          </div>
        </div>
      
      
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      showOrgDropdown: false,
    }
  },
  computed: {
    org() {
      return this.$store.getters["org/org"];
    },
    orgs() {
      return this.$store.getters["org/orgs"];
    },
    plan() {
      return this.$store.getters["auth/plan"];
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.showOrgDropdown = false;
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('login')
      })
    },
    close (e) {
      if (!this.$el.contains(e.target)) {
        this.showOrgDropdown = false
        this.isOpen = false
      }
    },
     orgChange(org) {
      this.$store.dispatch('org/setOrg', {org: org.org.id, default: true}).then(()=> {
        this.$store.dispatch('org/getOrgs')
        this.showOrgDropdown = false
      })
    },
    goToCustomerPortal(){
            this.$store.dispatch('checkout/createPortal').then((response) => {
                window.location.href = response.data.url
            })
        }
  },
  created(){
    this.$store.dispatch('auth/getPlan')
  },
  mounted () {
    document.addEventListener('click', this.close)
  },
}
</script>
<style>
:root {
  --eastern-blue-500: #0891b2;
  --eastern-blue-600: #0e7490;
}

.upgrade-button {
  background: linear-gradient(to right, #f59e0b, #d97706) !important;  /* Amber 500 to 600 */
  color: white !important;
  padding: 0.5rem 1.5rem !important;
  border-radius: 0.5rem !important;
  margin: 0 0.75rem !important;
  gap: 0.5rem !important;
  transition: all 0.2s ease-in-out !important;
  display: inline-flex !important;
  align-items: center !important;
}

.upgrade-button:hover {
  background: linear-gradient(to right, #d97706, #b45309) !important;  /* Amber 600 to 700 */
  transform: scale(1.05) !important;
  box-shadow: 0 4px 6px -1px rgba(245, 158, 11, 0.1), 0 2px 4px -2px rgba(245, 158, 11, 0.1) !important;
}

.upgrade-button svg {
  margin-right: 0.5rem !important;
}
</style>